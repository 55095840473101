import './bootstrap';
import Inputmask from "inputmask";
import 'laravel-datatables-vite';
import Alpine from 'alpinejs';
import * as bootstrap from 'bootstrap'
import moment from 'moment';
import Dropzone from 'dropzone';
import 'jquery-datetimepicker';
import pwstrength from 'pwstrength-bootstrap/dist/pwstrength-bootstrap.js';
import Swal from 'sweetalert2';
import select2 from 'select2';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
select2($);
window.Swal = Swal;
window.pwstrength = pwstrength;
window.Dropzone = Dropzone;
window.moment = moment;
window.Alpine = Alpine;
window.Calendar = Calendar;
window.dayGridPlugin = dayGridPlugin;
window.timeGridPlugin = timeGridPlugin;
window.listPlugin = listPlugin;
Alpine.start();
